import { useMatches } from "@remix-run/react";
import { createContext, useMemo } from "react";

export const RouteKeyContext = createContext<{ keys: ReadonlySet<string> }>({
  keys: new Set<string>(),
});

interface HandleWithRouteKey {
  routeKeys?: string[];
}

export function RouteKeyContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const matches = useMatches();
  const keys = useMemo(() => {
    const nextKeys = new Set<string>();
    matches.forEach((match) => {
      const handle = match.handle as Partial<HandleWithRouteKey>;
      if (handle && handle.routeKeys) {
        handle.routeKeys.forEach((routeKey) => {
          nextKeys.add(routeKey);
        });
      }
    });
    return nextKeys;
  }, [matches]);

  return (
    <RouteKeyContext.Provider
      value={{
        keys,
      }}
    >
      {children}
    </RouteKeyContext.Provider>
  );
}
